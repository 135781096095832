<template>
    <v-row class="fill-height">
        <v-col>
            <v-row>
                <v-col lg="12" md="12">
                    <v-card>
                        <v-card-title>
                            <v-icon>mdi-account-box</v-icon>
                            Banner
                            <v-spacer></v-spacer>
                            <v-text-field
                                class="mr-3"
                                append-icon="mdi-magnify"
                                label="Busca"
                                single-line
                                hide-details
                                @keydown.enter="buscar()"
                                @click:append="buscar()"
                            ></v-text-field>
                            <v-spacer></v-spacer>
                            <v-btn outlined :to="'/banner-create'">
                                <v-icon left>mdi-plus-thick</v-icon>
                                Novo Banner
                            </v-btn>
                        </v-card-title>
                    </v-card>
                </v-col>
                <v-col lg="12" md="12">
                    <v-row justify="center">
                        <v-col lg="6" md="6">
                            <v-card class="text-center">
                                <v-data-table :headers="headers" :items="banners" />
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store";
import {
    BANNER_GET,
} from "@/store/actions/banner.type";
import {
    BANNER_RESET_STATE
} from "@/store/mutations/banner.type";

export default {
    name: 'Banner',
    
    data: () => ({
        headers: [
            {text: "Imagem", value: "image"},
            {text: "Nome", value: "name"},
        ],
        banners: []
    }),

    async beforeRouteUpdate(to, from, next) {
        // Reset state if user goes from /editor/:id to /editor
        // The component is not recreated so we use to hook to reset the state.
        await store.commit(BANNER_RESET_STATE);
        return next();
    },
    async beforeRouteEnter(to, from, next) {
        await store.commit(BANNER_RESET_STATE)
        await store.dispatch(BANNER_GET);
        //await store.dispatch(PROFILE_DAYS_OFF_GET);
        return next();
    },
    computed:{
        ...mapGetters(["banner"])
    }
}
</script>